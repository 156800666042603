
import { defineComponent } from 'vue';
import DashboardGrid from '@/components/DashboardGrid.vue';

export default defineComponent({
  name: 'OrdersOnHoldTab',
  components: {
    DashboardGrid,
  }
});
